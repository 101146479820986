.slider .item {
  position: relative;
}

.slider .item img {
  display: block;
  width: 100%;
  height: auto;
}

.slider .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: #fff;
  padding: 10px;
  text-align: center;
}

.slider .overlay .text h4,
.slider .overlay .text p {
  margin: 0;
}
