.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* App.css or your custom CSS file */


.main {
  flex: 1;
}

.carousel-item img {
  object-fit: cover;
  object-position: center;
  height: 50vh;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .carousel-item img {
    height: 30vh;
  }

  .resultcard {
    /* height: 700px; */
  }
}

.searchresults {
  margin-top: 20px;

}

.resultcard {
  display: block;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow .25s;
  /* height: 500px; */
}

.resultcard:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.result-location {
  font-size: 12px;
}

.result-title{font-size: 16px;}

.result-img {
  height: 200px;
  width: 100%;
  object-fit: cover;

}

.result-content {
  height: 200px;
}

.result-button {
  height: 40px;
}

.btn-mod.btn-medium {
  height: auto;
  padding: 10px 35px;
  font-size: 13px;
}

.btn-mod.btn-border {
  color: #000000;
  border: 2px solid #000000;
  background: transparent;
}

.btn-mod,
a.btn-mod {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px 13px;
  color: #fff;
  background: rgba(34, 34, 34, .9);
  border: 2px solid transparent;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
  -moz-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
  -o-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
  -ms-transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
  transition: all 0.2s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.btn-mod.btn-border:hover,
.btn-mod.btn-border:focus {
  color: #fff;
  border-color: transparent;
  background: #000;
}

.btn-mod:hover,
.btn-mod:focus,
a.btn-mod:hover,
a.btn-mod:focus {
  font-weight: 400;
  color: rgba(255, 255, 255, .85);
  background: rgba(0, 0, 0, .7);
  text-decoration: none;
  outline: none;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Reveal icon on hover */

a.btn.btn-mod.btn-border.animation-one span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.3s;
}

a.btn.btn-mod.btn-border.animation-one span:after {
  font-family: FontAwesome;
  content: "\f105";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.3s;
}

a.btn.btn-mod.btn-border.animation-one:hover span {
  padding-right: 25px;
}

a.btn.btn-mod.btn-border.animation-one:hover span:after {
  opacity: 1;
  right: 0;
}

.text-small{
  font-size: 12px;
}
.breadcrump{
  padding: 70px 50px;
}

.slider{
  padding: 20px 40px;
}

